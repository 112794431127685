<template>
  <div class="manage-consult">
    <div class="banner" v-if="banners != ''">
      <!-- <el-carousel :interval="4000">
        <el-carousel-item v-for="(item, i) in banners" :key="i"> -->
      <img
        @dragstart.prevent
        :src="banners[0].img"
        width="100%"
        height="100%"
        :style="{ cursor: banners[0].linkUrl ? 'pointer' : '' }"
        @click="gotoNewPage(banners[0].linkUrl)"
      />
      <!-- </el-carousel-item>
      </el-carousel> -->
    </div>
    <div class="content-box-one">
      <div class="title">
        <Title :title="'服务内容'" :enTitle="'SERVICE CONTENT'"></Title>
      </div>
      <div class="items-box">
        <div class="box-left">
          <div class="common-box box-bg-one">
            <div class="mask" @click="scrollToCustom">
              <div
                class="mask-txt"
                style="
                  font-size: 20px;
                  color: rgba(250, 252, 254, 1);
                  position: relative;
                  left: 80px;
                  text-decoration: none;
                "
              >
                立即定制
              </div>
              <div class="mask-icon">
                <img
                  @dragstart.prevent
                  src="../../../assets/img/consult/manageConsult/arrow-left.png"
                  alt=""
                />
              </div>
            </div>
            <div class="box-one">
              <div class="box-icon"></div>
              <div class="box-title">战略咨询</div>
              <div class="box-txt">
                提供企业中长期发展战略规划、战略执行与落地方案设计、业务转型战略规划、战略评估与调整等咨询服务。
              </div>
            </div>
            <div class="box-two"></div>
          </div>
          <div class="common-box box-bg-two">
            <div class="mask" @click="scrollToCustom">
              <div
                class="mask-txt"
                style="
                  font-size: 20px;
                  color: rgba(250, 252, 254, 1);
                  position: relative;
                  left: 80px;
                  text-decoration: none;
                "
              >
                立即定制
              </div>
              <div class="mask-icon">
                <img
                  @dragstart.prevent
                  src="../../../assets/img/consult/manageConsult/arrow-left.png"
                  alt=""
                />
              </div>
            </div>
            <div class="box-one">
              <div class="box-icon"></div>
              <div class="box-title">企业管理咨询</div>
              <div class="box-txt">
                提供组织管理、决策支持体系建设、人才素质模型构建与人才测评、薪酬与绩效管理、员工激励、知识管理及科研课题研究等咨询服务。
              </div>
            </div>
            <div class="box-two"></div>
          </div>
        </div>
        <div class="box-right">
          <div class="common-box box-bg-three">
            <div class="mask" @click="scrollToCustom">
              <div
                class="mask-txt"
                style="
                  font-size: 20px;
                  color: rgba(250, 252, 254, 1);
                  position: relative;
                  left: 80px;
                  text-decoration: none;
                "
              >
                立即定制
              </div>
              <div class="mask-icon">
                <img
                  @dragstart.prevent
                  src="../../../assets/img/consult/manageConsult/arrow-left.png"
                  alt=""
                />
              </div>
            </div>
            <div class="box-one">
              <div class="box-icon"></div>
              <div class="box-title">市场咨询</div>
              <div class="box-txt">
                提供市场环境分析、市场机会洞察、市场布局调整、业务开发策略、客户开发策略、市场竞争策略、项目跟踪服务等咨询服务。
              </div>
            </div>
            <div class="box-two"></div>
          </div>
          <div class="common-box box-bg-four">
            <div class="mask" @click="scrollToCustom">
              <div
                class="mask-txt"
                style="
                  font-size: 20px;
                  color: rgba(250, 252, 254, 1);
                  position: relative;
                  left: 80px;
                  text-decoration: none;
                "
              >
                立即定制
              </div>
              <div class="mask-icon">
                <img
                  @dragstart.prevent
                  src="../../../assets/img/consult/manageConsult/arrow-left.png"
                  alt=""
                />
              </div>
            </div>
            <div class="box-one">
              <div class="box-icon"></div>
              <div class="box-title">项目咨询</div>
              <div class="box-txt">
                提供项目立项建议书编制、可行性研究报告编制、商业计划书编制、管理策划书编制等咨询服务。
              </div>
            </div>
            <div class="box-two"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box-two">
      <div class="title">
        <Title :title="'服务案例'" :enTitle="'SERVICE CASE'"></Title>
      </div>
      <div class="items-box">
        <div class="book-box">
          <div class="swiper-button-prev"></div>
          <swiper
            class="swiper-wrapper"
            ref="regionalSwipers"
            :options="swiperOptions"
          >
            <swiper-slide
              class="swiper-slide"
              v-for="(item, i) in bookImgList"
              :key="i"
            >
              <div class="box-one">
                <div class="mask"></div>
                <div class="box-top">
                  <img
                    @dragstart.prevent
                    :src="item.url"
                    alt=""
                    style="z-index: 10"
                  />
                </div>
                <div class="box-bottom">{{ item.title }}</div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>
    <div class="content-box-three">
      <div class="title">
        <Title :title="'服务流程'" :enTitle="'SERVICE PROCESS'"></Title>
      </div>
      <div class="items-box">
        <div class="img-box">
          <img
            src="../../../assets/img/consult/manageConsult/communicate.png"
            alt=""
            width="100%"
            height="100%"
          />
          <span class="item-txt">前期沟通</span>
        </div>
        <div class="line">
          <img
            src="../../../assets/img/consult/manageConsult/progressline.png"
            alt=""
            width="100%"
            height="100%"
          />
        </div>
        <div class="img-box">
          <img
            src="../../../assets/img/consult/manageConsult/confirm.png"
            alt=""
            width="100%"
            height="100%"
          />
          <span class="item-txt">确认需求</span>
        </div>
        <div class="line">
          <img
            src="../../../assets/img/consult/manageConsult/progressline.png"
            alt=""
            width="100%"
            height="100%"
          />
        </div>
        <div class="img-box">
          <img
            src="../../../assets/img/consult/manageConsult/amount.png"
            alt=""
            width="100%"
            height="100%"
          />
          <span class="item-txt">报价磋商</span>
        </div>
        <div class="line">
          <img
            src="../../../assets/img/consult/manageConsult/progressline.png"
            alt=""
            width="100%"
            height="100%"
          />
        </div>
        <div class="img-box">
          <img
            src="../../../assets/img/consult/manageConsult/aggree.png"
            alt=""
            width="100%"
            height="100%"
          />
          <span class="item-txt">签订协议</span>
        </div>
        <div class="line">
          <img
            src="../../../assets/img/consult/manageConsult/progressline.png"
            alt=""
            width="100%"
            height="100%"
          />
        </div>
        <div class="img-box">
          <img
            src="../../../assets/img/consult/manageConsult/project.png"
            alt=""
            width="100%"
            height="100%"
          />
          <span class="item-txt">项目实施</span>
        </div>
        <div class="line">
          <img
            src="../../../assets/img/consult/manageConsult/progressline.png"
            alt=""
            width="100%"
            height="100%"
          />
        </div>
        <div class="img-box">
          <img
            src="../../../assets/img/consult/manageConsult/question.png"
            alt=""
            width="100%"
            height="100%"
          />
          <span class="item-txt">过程沟通</span>
        </div>
        <div class="line">
          <img
            src="../../../assets/img/consult/manageConsult/progressline.png"
            alt=""
            width="100%"
            height="100%"
          />
        </div>
        <div class="img-box">
          <img
            src="../../../assets/img/consult/manageConsult/result.png"
            alt=""
            width="100%"
            height="100%"
          />
          <span class="item-txt">交付成果</span>
        </div>
        <div class="line">
          <img
            src="../../../assets/img/consult/manageConsult/progressline.png"
            alt=""
            width="100%"
            height="100%"
          />
        </div>
        <div class="img-box">
          <img
            src="../../../assets/img/consult/manageConsult/service.png"
            alt=""
            width="100%"
            height="100%"
          />
          <span class="item-txt">跟踪服务</span>
        </div>
      </div>
    </div>
    <div class="content-box-four" id="dataForm">
      <div class="title">
        <Title
          :title="'提交需求'"
          :enTitle="'SUBMIT REQUIREMENTS'"
          style="margin-top: 20px"
        ></Title>
      </div>
      <div class="items-box">
        <div class="box-left">
          <div class="left-title">立即定制，不一样的需求</div>
          <div class="left-txt">联系建筑企业经营决策支持平台</div>
          <div class="left-txt2">业务咨询 {{ servicePhone }}</div>
          <div class="left-txt3"></div>
        </div>
        <div class="box-right">
          <el-form
            :model="dataPO"
            :rules="rules"
            ref="dataPO"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="您的姓名"
                  prop="contactName"
                  class="title-item"
                >
                  <el-input
                    v-model.trim="dataPO.contactName"
                    maxlength="200"
                    placeholder="请输入您的姓名"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="12">
                <el-form-item label="您的联系方式" prop="contactNumber">
                  <el-input
                    v-model.trim="dataPO.contactNumber"
                    placeholder="请输入联系座机号或手机号"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="12">
                <el-form-item
                  label="您的单位"
                  prop="companyName"
                  class="title-item"
                >
                  <el-input
                    v-model.trim="dataPO.companyName"
                    maxlength="200"
                    placeholder="请输入您的单位"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="12">
                <el-form-item label="您的职务" prop="contactPost">
                  <el-input
                    v-model.trim="dataPO.contactPost"
                    maxlength="200"
                    placeholder="请输入您的职务"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="12">
                <el-form-item label="您的行业" prop="sector" class="title-item">
                  <el-input
                    v-model.trim="dataPO.sector"
                    maxlength="200"
                    placeholder="请输入您的行业"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="12">
                <el-form-item
                  label="您期望的交付时间"
                  prop="expectedDeliveryDate"
                >
                  <el-date-picker
                    type="date"
                    placeholder="请选择时间"
                    v-model="dataPO.expectedDeliveryDate"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    style="width: 100%"
                  ></el-date-picker> </el-form-item
              ></el-col>
              <el-col :span="24">
                <el-form-item
                  label="需求描述"
                  prop="detail"
                  class="desc-item title-item"
                >
                  <el-input
                    type="textarea"
                    v-model.trim="dataPO.detail"
                    :rows="6"
                    maxlength="1000"
                    resize="none"
                    placeholder="限1000字以内"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item class="title-item">
                  <el-button type="primary" @click="submitForm"
                    >立即定制</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title";
import { mapGetters } from "vuex";
export default {
  components: {
    Title
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      const moblie =
        "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
      const tel = /^(\d{3,4}-)?\d{7,8}$/;
      if (!new RegExp(moblie).test(value) && !tel.test(value) && value !== "") {
        callback(new Error("请输入格式正确的联系方式"));
      } else {
        callback();
      }
    };
    return {
      banners: [
        {
          linkUrl: "",
          img: ""
        }
      ],
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      bookImgList: [
        {
          title: "中国中铁“十四五”发展规划编制咨询",
          url: require("../../../assets/img/consult/case/1.jpg")
        },
        {
          title: "中国工程院、国家住建部“中国建筑企业能力提升工程”咨询研究",
          url: require("../../../assets/img/consult/case/2.jpg")
        },
        {
          title: "工程项目部主要管理岗位胜任力模型构建与应用",
          url: require("../../../assets/img/consult/case/3.jpg")
        },
        {
          title: "轨道交通产业园项目可行性研究",
          url: require("../../../assets/img/consult/case/4.jpg")
        },
        {
          title: "济南市全域轨道交通一体化新型发展格局研究",
          url: require("../../../assets/img/consult/case/5.jpg")
        },
        {
          title: "中国中铁长三角区域发展战略研究",
          url: require("../../../assets/img/consult/case/6.jpg")
        },
        {
          title: "老旧小区停车场开发政策研究及投资建议",
          url: require("../../../assets/img/consult/case/7.jpg")
        },
        {
          title: "中铁开投“城市轨道交通产业链高质量发展战略研究”",
          url: require("../../../assets/img/consult/case/8.jpg")
        },
        {
          title: "中铁四局工程项目减负与增效研究",
          url: require("../../../assets/img/consult/case/9.jpg")
        },
        {
          title: "中铁四局工程总承包能力建设研究",
          url: require("../../../assets/img/consult/case/10.jpg")
        },
        {
          title: "山东高速集团“品质工程”创建方案",
          url: require("../../../assets/img/consult/case/11.jpg")
        },
        {
          title: "大型机制砂智能工厂项目可行性研究",
          url: require("../../../assets/img/consult/case/12.jpg")
        },
        {
          title: "深圳地铁EPC项目管理策划方案",
          url: require("../../../assets/img/consult/case/13.jpg")
        }
      ],
      swiperOptions: {
        autoplay: true,
        loop: false,
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        autoplay: 6000,
        // speed: 4000,
        slidesPerGroup: 4,
        slidesPerView: 4,
        spaceBetween: 10,
        simulateTouch: true //禁止鼠标拖动切换
      },
      dataPO: {
        contactName: "",
        contactNumber: "",
        companyName: "",
        contactPost: "",
        sector: "",
        expectedDeliveryDate: "",
        detail: "",
        terminal: "60441001"
      },

      rules: {
        contactName: [
          { required: true, message: "请输入您的姓名", trigger: "blur" }
        ],
        companyName: [{ required: true, message: "请输入您的单位", trigger: "blur" }],
        contactNumber: [
          {
            required: true,
            message: "请输入联系座机号或手机号",
            trigger: "blur"
          },
          {
            validator: validatePhone
          }
        ],
        expectedDeliveryDate: [
          { required: true, message: "请选择您期望的交付时间", trigger: "blur" }
        ],
        detail: [
          { required: true, message: "请输入您的需求描述", trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["servicePhone"]),
    swiper() {
      return this.$refs.regionalSwipers.$swiper;
    } 
  }, 
  mounted() {
    // 数据埋点
    this.$api.home.buriedPoint({
      terminal: "60441001",
      consumerId: this.$session.getUsers()
        ? this.$session.getUsers().consumerId
        : "",
      positionCode: "604410011008"
    });
    this.getBannerList();
  },
  methods: {
    // 获取轮播
    getBannerList() {
      this.$api.home
        .getBannerList({
          code: "10051002",
          platform: 1
        })
        .then(res => {
          this.banners = res.data;
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 立即定制滚动事件
    scrollToCustom() {
      let anchor = document.querySelector("#dataForm");
      window.scrollTo({
        top: anchor.offsetTop,
        behavior: "smooth"
      });
    },
    // banner点击跳转
    gotoNewPage(url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        let anchor = document.querySelector("#dataForm");
        window.scrollTo({
          top: anchor.offsetTop,
          behavior: "smooth"
        });
      }
    },
    // 表单提交事件
    submitForm() {
      this.$refs["dataPO"].validate(valid => {
        if (valid) {
          this.$api.consult
            .consultingCustom(this.dataPO)
            .then(() => {
              this.$message.success("提交成功");
              this.$refs.dataPO.resetFields();
            })
            .catch(msg => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
